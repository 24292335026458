@import '../../styles/utilities';

.container {
  position: relative;
  width: 100%;
  min-width: $container-min-width-mobile;
  margin: 0 auto;
  padding: 0 $container-padding;

  @include media(desktop-only) {
    max-width: $container-max-width;
  }

  @include media(mobile) {
    padding: 0 $container-padding-mobile;
  }
}