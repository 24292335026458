#root {
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  .app-body {
    display: flex;
    min-height: 100vh;
    justify-content: space-between;
    flex-direction: column;
  }
}