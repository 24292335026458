.modal-success {
  text-align: center;

  > * {
    margin-top: 1rem;
  }

  button {
    width: 100%;
  }
}