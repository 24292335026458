@import '../../styles/utilities';

.section-contact__inner {
  display: flex;
  align-items: center;

  @include media(tablet) {
    flex-direction: column;
  }

  .section-contact__content {
    flex: 0 1 480px;
    display: flex;
    flex-direction: column;
    min-height: 470px;
    padding: 6rem 4rem;
    z-index: 10;

    @include media(tablet) {
      flex: 1;
      width: 100%;
      min-height: auto;
      padding: 3rem;
    }

    @include media(mobile) {
      padding: 2rem;
    }

    .section-contact__logo {
      text-align: center;
    }

    .section-contact__info {
      display: flex;
      flex-wrap: wrap;
      margin: 1rem -1rem 0;

      .contact-info {
        flex: 1 50%;
        min-width: 200px;
        padding: 1rem;

        .contact-info__title {
          margin-bottom: .5rem;
          margin-right: .5rem;
        }

        p {
          font-size: $font-size-md-1;
        }

        .icon-box {
          display: flex;
          align-items: center;
        }

        .icon {
          position: relative;
          width: 26px;
          height: 24px;

          &:nth-child(1) {
            z-index: 3;
          }

          &:nth-child(2) {
            margin-left: -15px;
            z-index: 2;
          }

          &:nth-child(3) {
            margin-left: -15px;
            z-index: 1;
          }
        }
      }
    }
  }

  .section-contact__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;

    @include media(mobile) {
      margin-top: 1rem;
    }

    @include media(mobile-sm) {
      flex-direction: column;
    }

    button,
    p {
      margin-top: 1rem;
    }

    button {
      min-width: 180px;
      @include media(mobile) {
        min-width: 50%;
      }
    }

    p {
      margin-left: 1.2rem;
      font-size: $font-size-sm-2;
    }
  }

  .section-contact__map {
    flex: 1;
    position: relative;
    min-width: 300px;
    min-height: 370px;

    @include media(tablet) {
      min-width: auto;
      width: 90%;
    }

    .ya-map {
      position: relative;
      width: 100%;
      height: 370px;
    }

    @include media(mobile-sm) {
      min-height: 250px;
      .ya-map {
        height: 250px;
      }
    }
  }
}