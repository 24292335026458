@import '../../../styles/utilities';

.step-status {
  display: flex;
  align-items: center;
}

.step-status__step-num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: .6rem;
  padding: .6rem;
  color: $color-green;
  font-weight: 800;
  border: 2px solid currentColor;
  border-radius: 50%;
}