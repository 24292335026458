@import '../../styles/utilities';

.delivery__content {
  display: flex;
  margin-top: 5.6rem;

  @include media(mobile) {
    flex-direction: column;
    margin-top: 3rem;
  }

  > div {
    flex: 1;
  }
}

.delivery__shipping,
.delivery__payment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 3rem;

  @include media(desktop) {
    flex-direction: column;
  }

  @include media(tablet) {
    padding: 1rem 2rem;
  }

  > div {
    flex: 1;

    &.delivery__payment-text,
    &.delivery__shipping-text {
      min-width: 240px;
      padding-left: 2rem;

      @include media(desktop) {
        min-width: auto;
      }

      @include media(tablet) {
        padding-left: 0;
      }

      ul {
        color: $color-grey-3;
        font-size: $font-size-md-1;
      }

      p {
        font-size: $font-size-md-1;
      }

      .delivery__content-title {
        display: inline-block;
        margin-bottom: .6rem;
      }

      p + .delivery__content-title {
        margin-top: 2rem;
        color: $color-grey-1;
      }
    }

    &.delivery__payment-image,
    &.delivery__shipping-image {
      text-align: center;
    }
  }
}