@import '../../styles/utilities';

.slider-products {
  .slider-products__slider {
    max-width: 960px;
    margin: 0 auto;
  }

  .slick-list {
    padding-bottom: 2rem;
  }

  .slider-products__slide {
    position: relative;
    max-width: 320px;
    min-height: 540px;
    padding: 2rem;
    margin: 1rem 1.5rem;
    border: 1px solid $color-grey-5;
    background: $color-white;
    border-radius: 10px;
    box-shadow: 0 20px 18px rgba($color-black, 0.1) !important;
    @include noselect-touch;
    @include noselect;

    @include media(desktop) {
      margin: 1rem;
    }

    @include media(mobile) {
      margin: 1rem auto;
      box-shadow: 0 10px 10px rgba($color-black, 0.1) !important;
    }

    @include media(mobile-sm) {
      min-height: auto;
      box-shadow: 0 10px 10px rgba($color-black, 0.1) !important;
    }

    > div {
      display: flex;
      align-items: center;
    }
  }

  .slick-dots {
    position: relative !important;
    bottom: auto;
  }

  .slick-prev {
    left: -3.6vw !important;
  }

  .slick-next {
    right: -3.6vw !important;
  }
}


// main product
.main-product {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-product__image {
  position: relative;

  img {
    margin: 0 auto;
  }
}

.main-product__price,
.main-product__title {
  margin-top: 1.5rem;
}

.main-product__price {
  display: flex;
  align-items: center;

  .price-amount {
    margin-right: 1rem;
  }

  .price {
    margin-right: 1rem;
    color: $color-grey-4;
    font-size: $font-size-md-1;
  }

  .percent {
    color: $color-fuchsia-1;
    font-size: $font-size-md-1;
  }
}

.main-product__title {
  text-align: center;
}

.main-product__rating {
  position: relative;
  margin: 1.2rem 0;

  .stars {
    margin-right: .4rem;

    svg + svg {
      margin-left: .5rem;
    }
  }
}

.main-product__review {
  color: $color-grey-3;
  font-size: $font-size-sm-2;
}

.main-product__order {
  margin-top: auto;
  display: flex;
  flex-direction: column;

  button {
    min-width: 200px;
  }

  .note {
    margin-top: 1rem;
    color: $color-grey-3;
    font-size: $font-size-sm-2 !important;
  }
}

// pane
.pane {
  display: flex;
  justify-content: center;
}

.pane-tab {
  margin: 3rem .8rem 3rem;
  @include noselect-touch;
  @include noselect;

  @include media(mobile) {
    margin: 2rem .8rem 2rem;
  }

  &.active {
  }
}