@import '../../styles/utilities';

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  @include noselect-touch;
  @include noselect;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;

    @include media(desktop) {
      // height: 200px;
      height: auto;
      flex-direction: column;
      align-items: flex-start;

      & > div {
        margin-top: 1rem
      }
    }
  }

  &__logo {
    @include media(desktop) {
      order: 0;
    }
  }

  &__contact {
    display: flex;
    flex: 1;

    @include media(desktop) {
      order: 1;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  &__contact-icon {
    margin-right: 1rem;
    @include media(desktop) {
      width: 20px;
    }
  }

  &__contact-text {
    strong {
      font-size: $font-size-md-3;
      font-weight: 800;
    }

    a {
      color: $color-grey-1;

      &:hover {
        color: $color-primary;
      }
    }
  }

}