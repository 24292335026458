@import '../../../styles/utilities';

.check-agree {
  display: flex;
  justify-content: center;
  cursor: pointer;
  @include noselect;

  .check-agree__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: .6rem;
    width: 42px;
    height: 28px;
    background: $color-white;
    border-radius: 2px;
    @include transition;

    &.error {
      box-shadow: 0 0 7px rgba($color-fuchsia-light, .7);
    }

    input[type='checkbox'] {
      display: none;
    }

    svg {
      width: 12px;
      height: 12px;

      path {
        stroke: $color-white;
      }
    }

    &.checked {
      background: $color-fuchsia;

      svg {
        path {
          stroke: $color-white;
        }
      }
    }

  }

  .check-agree__text {
    font-size: $font-size-md-1;
    line-height: 1.1;
  }
}