@import '../../styles/utilities';

.modal-video {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  max-width: none;
  background: $color-black;
  border-radius: 0;

  video {
    width: auto;
    height: 90%;
  }

  .modal-close {
    right: 20px;
    top: 20px;

    svg {
      fill: $color-white;
    }
  }
}

.btn-play {
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 50%;
  border: 1px dashed rgba($color-fuchsia, .5);
  @include transition(transform);

  &:hover {
    border: 1px dashed rgba($color-fuchsia, 1);
    transform: scale(1.1);
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    border: 2px solid rgba($color-fuchsia, .7);
    opacity: 0;
    animation: 2.3s ease-in 2.5s infinite fading-anim;
    pointer-events: none;
  }

  &:before {
    width: 100%;
    height: 100%;
    border: 2px solid rgba($color-yellow, .7);
    animation: 2.3s ease-in 2.8s infinite fading-anim;
  }

  .icon-play {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    opacity: 0.7;
    background: linear-gradient(137.68deg, #F0F115 24.05%, #C90578 51.42%, #ACFF00 77.67%);
  }
}

@keyframes fading-anim {
  0% {
    opacity: .4;
  }
  40% {
    transform: scale(3);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}