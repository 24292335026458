@import '../../../styles/utilities';

.quiz-status {
  position: relative;
  padding: 1rem;
  background: #F2F2F2;
  border-radius: 10px;

  .quiz-status__text {
    position: relative;
    color: $color-grey-3;
    padding-left: 1rem;

    text-align: center;

    @include media(desktop) {
      padding-right: 10rem;
    }

    @include media(mobile) {
      text-align: left;
      font-size: $font-size-sm-2;
    }
  }

  .quiz-status__ico {
    position: absolute;
    top: calc(50% - 65px);
    right: -10px;
  }
}

.quiz-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 10px;
  background: $color-green-light;
  opacity: 0.4;
  @include transition;
}