.disabled {
  pointer-events: none;
}

.noscroll {
  overflow: hidden;
}

.d-none {
  display: none !important;
}