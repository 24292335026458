@import '../../styles/utilities';

.about {
  .about__inner {
    display: flex;

    @include media(tablet) {
      flex-direction: column;
    }

    .about__col {
      position: relative;
      flex: 1;

      &:nth-child(1) {
        align-self: flex-end;
        max-width: 413px;

        @include media(tablet) {
          align-self: center;
          margin-bottom: 2rem;
        }
      }

      &:nth-child(2) {
        padding-left: 3rem;
        padding-bottom: 3rem;

        @include media(tablet) {
          padding-left: 0;
        }
      }
    }

    .about__image {
      width: 100%;
      vertical-align: bottom;

      @include media(tablet) {
        width: auto;
        max-height: 400px;
        margin-bottom: 66px;
      }
    }

    .image-caption {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      bottom: 2rem;

      @include media(tablet) {
        flex-direction: row;
        bottom: 0;
      }

      .image-caption__name,
      .image-caption__status {
        border-radius: 6px;
        transform: skew(-10deg);
      }

      .image-caption__name {
        padding: 2rem;
        color: $color-white;
        background: $color-secondary;
      }

      .image-caption__status {
        padding: 1rem;
        color: $color-grey-6;
        background: $color-grey-5;
      }
    }

    .about__subheading {
      margin-bottom: 1.2rem;
    }

    .about__description,
    .about__text,
    .about__footer {
      margin-top: 4rem;

      @include media(tablet) {
        margin-top: 2rem;
      }
    }

    .about__description {
      line-height: 1.2;
      color: $color-grey-2;
    }

    .about__footer {
      display: flex;
      align-items: center;

      @include media(desktop) {
        flex-direction: column;
      }

      button {
        min-width: 220px;
      }

      p {
        color: $color-grey-3;
        margin: 1rem 0 1rem 2rem;
        font-size: $font-size-sm-2;

        @include media(desktop) {
          margin-left: 0;
        }

        @include media(tablet) {
          text-align: center;
        }
      }
    }
  }
}