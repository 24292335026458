@import '../../styles/utilities';

.slider-promo {
  position: relative;

  @include media(tablet) {
    // clear padding container
    margin-right: -$container-padding;
  }

  .slide {
    display: flex !important;
    justify-content: space-between;
    position: relative;
    padding: 1rem 0;
    overflow: hidden;
    @include noselect-touch;
    @include noselect;
  }

  .slide__col {
    display: flex;
    flex: 1;
    max-height: 1300px;

    @include media(tablet-only) {
      min-height: 680px;
    }

    @include media(tablet) {
      @media (orientation: landscape) {
        height: auto !important;
      }
    }

    // first col in slide
    &:nth-child(1) {
      max-width: 500px;
      justify-content: center;
      flex-direction: column;
      z-index: 2;

      @include media(desktop) {
        padding-top: 170px;
        justify-content: flex-start;
      }

      @include media(tablet) {
        padding-top: 220px;
      }

    }

    // two col slide
    &:nth-child(2) {
      @include media(wide) {
        margin-left: -10vw;
      }

      @include media(mobile) {
        margin-left: -160px;
      }

      @include media(mobile-sm) {
        margin-left: -180px;
      }
    }
  }

  .slide__subtitle {
    margin-top: 6rem;

    @include media(mobile) {
      margin-top: 0;
    }
    // badge
    p {
      @include media(desktop) {
        text-align: left !important;
        display: inline-block;
      }

      @include media(mobile) {
        font-size: $font-size-sm-2 !important;
      }
    }
  }

  .slide__title {
    margin: .6rem 0;

    @include media(mobile) {
      padding: 1.2rem .4rem;
      background: linear-gradient(to left, rgba($color-white, .8), rgba($color-white, 0));
      border-radius: 6px;
    }
  }

  .slide__text {
    max-width: 400px;
    margin: .6rem 0;
    padding: 1.2rem 0;
    font-size: $font-size-lg;

    //background to text
    @include media(mobile) {
      border-radius: 6px;
      padding: .4rem;
      background: linear-gradient(to left, rgba($color-white, .8), rgba($color-white, 0));
    }
  }

  .slide__footer {
    margin-top: 6rem;

    @include media(mobile) {
      margin-top: .6rem;

      button {
        width: 100%;
      }
    }
  }

  .slide__image {
    img {
      width: auto;
      max-width: 100%;
      min-width: 300px;
      min-height: 300px;
      max-height: 100%;
      height: 97%;
      object-fit: contain;
    }
  }

  .slide__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
    max-width: 300px;

    @include media(tablet) {
      display: none;
    }
  }

  .slide__info-text {
    margin-top: 4rem;

    strong {
      font-size: $font-size-md-3;
    }

    p {
      font-size: $font-size-md-1;
    }
  }

  .slick-dots {
    position: absolute;
    padding-right: $container-padding;
    bottom: 20px;
  }

  .slick-prev,
  .slick-next {
    top: auto;
    right: auto;
    left: 10px;
    bottom: 30px;
  }

  .slick-next {
    left: 60px;
  }
}

// video play bnt
.slider-promo-play-btn {
  position: absolute;
  width: 100px;
  height: 100px;
  top: calc(50% - 100px);
  left: calc(50% - 110px);
  z-index: 10;

  @include media(wide) {
    left: calc(50% - 80px);
  }

  @include media(tablet) {
    top: auto;
    left: auto;
    right: 50px;
    bottom: 50px;
  }

  @include media(mobile) {
    width: 70px;
    height: 70px;
    right: auto;
    left: 20px;
  }

  @include media(mobile-sm) {
    right: 30px;
    left: auto;
    bottom: 40px;
  }
}
