@import '../../styles/utilities';

.top-btn {
  &,
  svg {
    @include transition;
  }
}

.top-btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border: 6px solid rgba($color-fuchsia, .6);
  border-radius: 50%;
  transform: translateY(200px);
  cursor: pointer;
  z-index: 10;
  @include noselect-touch;

  &:hover {
    border-color: $color-fuchsia;
  }

  svg {
    transform: rotate(90deg);

    path {
      stroke: $color-fuchsia;
      fill: $color-fuchsia;
    }
  }

  &.visible {
    transform: translateY(0);
  }
}
