@import '../../styles/utilities';

.footer {
  padding-bottom: 4rem;

  .footer__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media(mobile) {
      flex-direction: column;
    }

    p {
      &:last-child {
        text-align: right;
      }
    }
  }

  p,
  a {
    margin: 0;
    font-size: $font-size-md-1;
  }

  a {
    text-decoration: underline;
    color: $color-grey-3;

    &:hover {
      text-decoration: none;
      color: $color-primary;
    }
  }
}