@font-face {
  font-family: 'Uni Neue';
  src: url('../fonts/UniNeueBook.eot');
  src: local('Uni Neue Book'), local('UniNeueBook'),
  url('../fonts/UniNeueBook.eot?#iefix') format('embedded-opentype'),
  url('../fonts/UniNeueBook.woff2') format('woff2'),
  url('../fonts/UniNeueBook.woff') format('woff'),
  url('../fonts/UniNeueBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../fonts/UniNeueRegular.eot');
  src: local('Uni Neue Regular'), local('UniNeueRegular'),
  url('../fonts/UniNeueRegular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/UniNeueRegular.woff2') format('woff2'),
  url('../fonts/UniNeueRegular.woff') format('woff'),
  url('../fonts/UniNeueRegular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../fonts/UniNeueBold.eot');
  src: local('Uni Neue Bold'), local('UniNeueBold'),
  url('../fonts/UniNeueBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/UniNeueBold.woff2') format('woff2'),
  url('../fonts/UniNeueBold.woff') format('woff'),
  url('../fonts/UniNeueBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/* Icons slick slider */
@font-face {
  font-family: 'slick';
  src: url('../fonts/slick/slick.eot');
  src: url('../fonts/slick/slick.eot?#iefix') format('embedded-opentype'),
  url('../fonts/slick/slick.woff') format('woff'),
  url('../fonts/slick/slick.ttf') format('truetype'),
  url('../fonts/slick/slick.svg#slick') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}