@import '../../../styles/utilities';

.step-finally {

  &.success {
    opacity: .4;
  }

  .step-finally__price {
    display: flex;
  }

  .quiz__price {
    padding: .5rem 1.5rem 1rem;
    text-align: left !important;
    border-radius: 10px;
    border: 1px solid $color-grey-5;

    @include media(mobile) {
      margin-top: 1.2rem;
    }
  }

  .step-finally__content {
    position: relative;
    margin: 2rem 0;
    -webkit-overflow-scrolling: touch;
  }

  .step-finally__inner {
    display: flex;
    padding-bottom: 1rem;

    > div {
      flex: 1;
    }
  }

  .step-finally__products {
    display: flex;
    margin: 0;

    .quiz-product {
      position: relative;
      flex: 1;
    }
  }
}