@import '../../../styles/utilities';

.step-choice {
  margin: 2rem 0;
  padding-bottom: 1rem;
  // overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  .step-choice__products {
    display: flex;
  }

  .quiz-product {
    position: relative;
    flex: 1;
  }

  .quiz-product:first-child {
    margin-left: 0 !important;
  }

  .quiz-product:last-child {
    margin-right: 0 !important;
  }
}