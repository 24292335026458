@mixin font-family($font-family: $font-primary) {
  font-family: $font-family;
}

@mixin noselect {
  user-select: none;
}

@mixin noselect-touch {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

@mixin transition($attr: all, $delay: $base-transition) {
  transition: $attr $delay ease-in-out;
}

@mixin selection {
  ::-moz-selection {
    @content;
  }
  ::selection {
    @content;
  }
}
