@import '../../styles/utilities';

.modal-overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color-black, 0.5);
  z-index: 10;
  @include transition(all);

  @media screen and (orientation: landscape) and (max-height: 350px) {
    align-items: flex-start;
  }
}

.modal {
  position: absolute;
  padding: 30px;
  max-width: 400px;
  min-height: 150px;
  margin: auto;
  background: $color-white;
  border-radius: 14px;

  @include media(mobile) {
    width: calc(100% - 40px);
  }

  .modal__title,
  .modal__subtitle {
    margin-bottom: .5rem;
    text-align: center;
  }
}

.modal-close {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  z-index: 5;
  @include noselect-touch;

  &:hover {
    svg {
      transform: rotate(-45deg);
      fill: rgba($color-black, 7);
    }
  }

  &:active {
    svg {
      fill: $color-grey-2;
    }
  }

  svg {
    width: 42px;
    height: 42px;
    fill: rgba($color-black, 0.6);
    transform: rotate(45deg);
    @include transition(all);
  }
}
