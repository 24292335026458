@import '../../../styles/utilities';

.quiz-product {
  margin: 0 1rem 1rem;
  width: 290px;
  cursor: pointer;
  @include noselect;
  @include noselect-touch;

  @include media(desktop) {
    min-width: 260px;
  }

  @include media(mobile) {
    width: auto;
  }

  @include media(mobile-sm) {
    min-width: 220px;
  }

  &__main {
    position: relative;
    border: 2px solid $color-grey-4;
    border-radius: 10px;

    &.active {
      border: 2px solid $color-green;
      box-shadow: 0 0 12px rgba($color-green, .5);

      .check-rounded {
        border: 2px solid $color-green;

        &:after {
          background: $color-green;
        }
      }
    }
  }

  &__image {
    position: relative;
    height: 200px;
    text-align: center;
    overflow: hidden;

    img {
      width: auto;
      height: 200px;
      background-size: cover;
    }
  }

  &__title {
    text-align: center;
    margin-top: 2.6rem;

    h4 {
      font-size: $font-size-md-2;
    }
  }

  .check-rounded {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    bottom: -20px;
    border-radius: 50%;
    background: $color-white;
    border: 2px solid $color-grey-4;
    z-index: 4;

    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $color-grey-4;
    }
  }
}
