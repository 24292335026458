@import '../../styles/utilities';

.features {
  .box-primary {
    padding: 5rem 6vw;

    @include media(mobile) {
      padding: 2rem;
    }
  }

  .features__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 5rem -1.3rem 0;

    @include media(tablet) {
      margin-top: 2rem;
    }
  }

  .feature-item {
    max-width: calc(33.3333% - 2.6rem);
    margin: 2rem 1.3rem;
    text-align: center;

    @include media(tablet) {
      flex: 1;
      max-width: none;
      min-width: 200px;
      margin: 1rem;
    }

    .feature-item__image {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 80px;

      img {
        width: auto;
        height: 100%;
      }
    }

    .feature-item__title {
      margin: 1rem 0 2rem;
    }

    .feature-item__text {
    }
  }
}