@import '../../../styles/utilities';

.form-primary {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  .form-status {
    margin-top: 1.2rem;
  }

  &.form-sending {
    .form-field {
      pointer-events: none;
      opacity: .35;
    }
  }

  .form-field {
    position: relative;
    margin-top: 2.5rem;

    .form-field__submit {
      margin-top: 1.6rem;
    }
  }

  .form-field__label {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    color: $color-grey-2;

    &::before {
      content: attr(data-title);
      position: absolute;
      display: flex;
      align-items: center;
      height: 100%;
      left: 14px;
      font-size: $font-size-sm-2;
      transition: 300ms all;
      pointer-events: none;
      @include noselect;
      @include transition(all);
    }

    &.focused,
    &.error {
      font-size: $font-size-sm;
    }

    &.focused {
      top: -13px;

      &::before {
        display: inline-block;
        height: auto;
        font-size: $font-size-sm-1;

        background: #fff;
        border-radius: 2px;
        box-shadow: 0 -1px 4px rgba($color-grey-2, 0.3);
        padding: 0.1rem 0.4rem;
      }
    }

    &.error {
      color: rgba($color-fuchsia-light, .7);
      opacity: 1;
    }
  }

  // phone field
  .form-field__phone-inner {
    position: relative;

    .form-field__label {
      &::before {
        left: 45px;
      }

      &.valid {
        // opacity: 0;
      }
    }
  }

  input[type='text'],
  input[type='tel'] {
    width: 100%;
    height: 45px;
    padding: 1.5rem 1.4rem;
    outline: none;
    outline-offset: 0;
    border-radius: 8px;
    font-size: $font-size-md-1;
    border: 1px solid $color-grey-5;
    background: $color-grey-7;
    @include transition(all);

    &:focus {
      background: $color-white;
      box-shadow: 0 0 10px rgba($color-grey-7, .7);
    }

    &.error {
      border-color: rgba($color-fuchsia, .8);
      box-shadow: 0 0 7px rgba($color-fuchsia-light, .4);
    }
  }

  input[type='tel'] {
    width: calc(100% - 40px);
    margin-left: 40px;
    border-radius: 0 8px 8px 0;
  }

  button[type='submit'] {
    width: 100% !important;
    max-width: none;
  }

  .form-field__required:after {
    content: '*';
    position: absolute;
    margin-left: 3px;
    color: $color-fuchsia-1;
  }
}