html {
  font-size: $font-size-base;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: inherit;
}

body {
  position: relative;
  background: $body-background;
  color: $body-color-text;
  @include font-family($body-font-family);
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: $body-line-height;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

input,
select,
textarea,
button {
  @include font-family($font-primary);
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  @include font-family($font-heading);
  color: $color-blueberry;
  line-height: $heading-line-height;
}

h1, .h1 {
  font-size: $heading-font-size-h1;
  font-weight: $heading-font-weight-h1;
}

h2, .h2 {
  font-size: $heading-font-size-h2;
  font-weight: $heading-font-weight-h2;
}

h3, .h3 {
  font-size: $heading-font-size-h3;
  font-weight: $heading-font-weight-h3;
}

h4, .h4 {
  font-size: $heading-font-size-h4;
  font-weight: $heading-font-weight-h4;
}

h1, .h1 {
  @include media(wide) {
    font-size: $font-size-xl-2;
  }

  @include media(desktop) {
    font-size: $font-size-xl;
  }

  @include media(tablet) {
    font-size: $font-size-xl;
  }

  @include media(mobile) {
    font-size: $font-size-lg;
  }
}

h2, .h2 {
  @include media(desktop) {
    font-size: $font-size-xl;
  }

  @include media(tablet) {
    font-size: $font-size-lg-1;
  }

  @include media(mobile) {
    font-size: $font-size-lg;
  }
}

h3, .h3 {
  @include media(desktop) {
    font-size: $font-size-lg;
  }

  @include media(mobile) {
    font-size: $font-size-md-3;
  }
}

h4, .h4 {
  @include media(desktop) {
    font-size: $font-size-md-3;
  }

  @include media(tablet) {
    font-size: $font-size-md-2;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  @include transition;

  &:hover {
    color: $link-color-hover;
    text-decoration: none;
  }
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: $paragraph-line-height;

  & + p {
    margin-top: 1.4rem;
  }

  @include media(tablet) {
    font-size: $font-size-md-1;
  }

  @include media(mobile) {
    font-size: $font-size-md;
  }
}

b,
strong {
  font-weight: bold;
}

.badge {
  display: inline;
  padding: 0.85rem 1.8rem;
  color: $color-grey-1;
  font-size: $font-size-sm-2 !important;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border-radius: 5px;
  background: $color-green-light-1;

  @include media(mobile) {
    display: inline-block;
    margin: 0 auto;
    text-align: center;
  }
}

ul {
  list-style: disc !important;
  margin-left: 2rem;

  li {
    margin: .6rem 0;
  }
}

.box-primary {
  border: 1px solid $color-grey-5;
  background: $color-white;
  border-radius: 10px;
  box-shadow: 0 20px 50px rgba($color-black, 0.1);
}

.section {
  position: relative;
  margin: 8rem 0;

  @include media(tablet) {
    margin: 3rem 0;

    &.quiz {
      margin-top: 8rem;
    }
  }

  &.about {
    margin-bottom: 0;
  }

  &.features {
    margin-top: 0;
  }
}

.section.delivery,
.slider-products {
  .heading {
    text-align: center;
  }
}

.section.quiz,
.section.features {
  .subheading {
    margin-bottom: 1.2rem;
  }

  .heading,
  .subheading {
    text-align: center;
  }

  @include media(desktop-only) {
    .heading {
      max-width: 80%;
      margin: 0 auto;
    }
  }
}

.justify-center {
  justify-content: center !important;
}

// bg images
.bg-item {
  position: absolute;
  z-index: -1;

  &--1 {
    top: -30px;
    left: -270px;
  }

  &--2 {
    right: -270px;
  }

  &--3 {
    top: -30px;
    left: -270px;
  }

  &--4 {
    top: 200px;
    right: -270px;
  }
}

// transforms
.modal-appearance {
  opacity: 0;

  .modal {
    transform: scale(0);
    transition-duration: 300ms;
  }
}

.modal-appearance-enter-done {
  opacity: 1;

  .modal {
    transform: scale(1);
  }
}