@import '../styles/utilities';

.page {
  margin-top: 10rem;
  padding: 3rem 0 6rem;

  @include media(desktop) {
    margin-top: 200px;
  }

  &__heading {
    margin-bottom: 4rem;
  }

  h3 {
    margin-top: 3rem;
  }

  h3 + p {
    margin-top: 2rem;
  }
}