// Responsive breakpoints mixin
@mixin media($res) {
  @if mobile-sm == $res {
    @media only screen and (max-width: #{$breakpoint-mobile-width-sm - 1}) {
      @content;
    }
  }

  @if mobile-sm-only == $res {
    @media only screen and (min-width: $breakpoint-mobile-width-sm) {
      @content;
    }
  }

  @if mobile == $res {
    @media only screen and (max-width: #{$breakpoint-mobile-width - 1}) {
      @content;
    }
  }

  @if mobile-only == $res {
    @media only screen and (min-width: $breakpoint-mobile-width) {
      @content;
    }
  }

  @if tablet == $res {
    @media only screen and (max-width: #{$breakpoint-tablet-width - 1}) {
      @content;
    }
  }

  @if tablet-only == $res {
    @media only screen and (min-width: $breakpoint-tablet-width) {
      @content;
    }
  }

  @if desktop == $res {
    @media only screen and (max-width: #{$breakpoint-desktop-width - 1}) {
      @content;
    }
  }

  @if desktop-only == $res {
    @media only screen and (min-width: $breakpoint-desktop-width) {
      @content;
    }
  }

  @if wide == $res {
    @media only screen and (max-width: #{$breakpoint-wide-width - 1}) {
      @content;
    }
  }

  @if wide-only == $res {
    @media only screen and (min-width: $breakpoint-wide-width) {
      @content;
    }
  }
}
