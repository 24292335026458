@import '../utilities';

// buttons
.slick-prev,
.slick-next {
  position: absolute;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: linear-gradient(264.29deg, #F652F7 -8.12%, #C70374 100%) !important;
  opacity: 0.7;
  cursor: pointer;
  z-index: 1;
  @include transition(transform, 100ms);

  &:hover,
  &:focus {
    opacity: 1;
    transform: scale(1.1);
  }
}

.slick-prev:before,
.slick-next:before {
  content: '';
}

.slick-next {
  svg {
    transform: rotate(180deg);
  }
}

.slick-disabled {
  visibility: hidden;
}

.slick-dots li.slick-active button:before {
  color: $color-primary;
}

// effects
.animate-floating {
  animation: floating 4s ease-in-out infinite;

  @keyframes floating {
    0% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(0, -10px, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
}
