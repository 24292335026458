@import '../../../styles/utilities';

.gradient-ellipse {
  position: absolute;
  z-index: -1;
  border: 0 solid green;

  &--primary {
    background: radial-gradient(38.5% 38.5%, #F652F7 0%, rgba(164, 128, 0, 0) 100%);
    mix-blend-mode: hard-light;

    @include media(desktop-only) {
      animation: zooming-primary 6s ease-in-out infinite;
    }
  }

  &--secondary {
    background: radial-gradient(38.5% 38.5%, #FAFF00 0%, rgba(219, 255, 0, 0) 100%);
    mix-blend-mode: hard-light;
    @include media(desktop-only) {
      animation: zooming-secondary 3s ease-in infinite;
    }
  }

  &--third {
    background: radial-gradient(38.5% 38.5%, #00E987 0%, rgba(255, 199, 0, 0) 100%);;
    mix-blend-mode: multiply;
    opacity: 0.5;
    @include media(desktop-only) {
      animation: zooming-secondary 4s ease-in-out infinite;
    }
  }
}

@keyframes zooming-primary {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: .3;
  }
  50% {
    transform: scale3d(1.5, 1.5, 1);
    opacity: .5;
  }
  100% {
    transform: scale3d(1, 1, 1);
    opacity: .3;
  }
}

@keyframes zooming-secondary {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: .3;
  }
  50% {
    transform: scale3d(1.5, 1.5, 1.5);
    opacity: .6;
  }
  100% {
    transform: scale3d(1, 1, 1);
    opacity: .3;
  }
}