.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  text-align: center;
  text-decoration: none !important;
  outline: none !important;
  cursor: pointer;
  border: 1px solid transparent;
  @include transition(all);
  @include noselect-touch;

  &--sm {
    min-width: 140px;
    padding: .6rem;
    font-size: $font-size-md;

    @include media(mobile) {
      font-size: $font-size-sm-2;
      padding: .6rem 1rem;
    }
  }

  &--md {
    min-width: 180px;
    padding: 1.4rem 2rem;
    font-size: $font-size-md-1;
    font-weight: 800;

    @include media(desktop) {
      font-size: $font-size-md;
      padding: 1.4rem;
    }

    @include media(mobile) {
      min-width: 80px;
      font-size: $font-size-sm-2;
      padding: 1.4rem 1rem;
    }

    @include media(mobile-sm) {
      padding: 1.4rem 1rem;
    }
  }

  &--lg {
    min-width: 260px;
    padding: 2.8rem 4rem;
    font-size: $font-size-md-2;
    font-weight: 800;

    @include media(tablet) {
      min-width: 200px;
    }

    @include media(mobile) {
      font-size: $font-size-md;
      padding: 1.8rem 1rem;
    }
  }

  &--e-scale {
    &:hover:not(:disabled) {
      transform: scale(1.05);
    }
  }

  &--e-moveup {
    &:hover:not(:disabled) {
      transform: translateY(-3px);
    }
  }
}

.btn-primary,
.btn-secondary,
.btn-third {
  position: relative;
  color: $color-white;
  border-radius: 8px;
  background: linear-gradient(260deg, $color-fuchsia -8%, $color-fuchsia-light 100%);

  &:disabled {
    opacity: .4;
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    background: $color-fuchsia;
    z-index: 1;
    opacity: 0;
    @include transition(opacity);
  }

  &__text {
    display: block;
    z-index: 2;
  }

  &:hover:not(:disabled) {
    color: $color-white;

    .btn-primary__bg {
      opacity: 1;
    }

    .icon {
      &--left {
        transform: translateX(-5px);
      }

      &--right {
        transform: translateX(5px);
      }
    }
  }

  .icon {
    display: inline-block;
    @include transition(transform);

    &--left {
      padding-right: .6rem;
    }

    &--right {
      padding-left: .6rem;
    }

    svg {
      path {
        fill: currentColor;
      }
    }
  }
}

.btn-secondary {
  color: $color-primary;
  background: $color-white;
  border: 1px solid currentColor;

  &:hover:not(:disabled) {
    color: $color-primary;
  }
}

.btn-third {
  color: $color-grey-3;
  background: $color-white;
  border: 1px solid currentColor;

  &:hover:not(:disabled) {
    color: $color-primary;
  }

  &.active:not(:disabled) {
    color: $color-fuchsia-light;
  }
}