@import '../../styles/utilities';

.info-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  font-weight: bold;
  line-height: 0;
  border-radius: 50%;
  cursor: pointer;
  background: $color-grey-8;
}

.info-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
  color: $color-white;
  background: rgba($color-black, .8);
  opacity: 0;
  border-radius: 10px;
  visibility: hidden;
  overflow: hidden;
  @include transition(opacity);

  p {
    font-size: $font-size-sm-2;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    z-index: 5;
    @include noselect-touch;

    &:hover {
      svg {
        transform: rotate(-45deg);
        fill: rgba($color-white, 7);
      }
    }

    &:active {
      svg {
        fill: $color-white;
      }
    }

    svg {
      width: 32px;
      height: 32px;
      fill: rgba($color-white, 0.6);
      transform: rotate(45deg);
      @include transition(all);
    }
  }
}