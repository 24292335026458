@import '../../../styles/utilities';

.form-status {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  &__success {
    text-align: center;
    color: green;
    font-weight: bold;
  }

  &__sending {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    font-weight: bold;
  }

  &__error {
    font-size: $font-size-md-1;
    font-weight: bold;
    color: $color-fuchsia;
  }

}

.loader-sm {
  margin-right: 5px;

  &:after {
    border: 3px solid green !important;
    border-color: green transparent green transparent !important;
  }
}