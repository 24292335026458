@import '../../styles/utilities';

.modal-sizes {
  max-width: 800px;
  width: 95%;
}

.table-sizes {
  border: 1px solid $color-fuchsia;

  .row {
    display: flex;
    min-width: 360px;

    &:nth-child(odd) {
      > div {
        background: $color-grey-7;
      }
    }

    > div {
      flex: 1;
      min-width: 100px;
      padding: 1rem;
      font-size: $font-size-md;

      @include media(mobile) {
        padding: .6rem;
      }

      &:first-child {
        min-width: 140px;
      }

      &.table-sizes__heading {
        // min-width: 100px;
        color: $color-white;
        background: $color-fuchsia;
        font-weight: 800;
      }
    }
  }
}