// Breakpoints
$breakpoint-mobile-width-sm: 360px;
$breakpoint-mobile-width: 576px;
$breakpoint-tablet-width: 768px;
$breakpoint-desktop-width: 992px;
$breakpoint-wide-width: 1200px;

// Colors socials icons
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-instagram: #833AB4;
$color-youtube: #FF0000;
$color-pinterest: #E60023;
$color-viber: #7C509A;

// Common colors
$color-black: #000;
$color-white: #fff;
$color-grey-1: #333333;
$color-grey-2: #4F4F4F;
$color-grey-3: #828282;
$color-grey-4: #BDBDBD;
$color-grey-5: #E0E0E0;
$color-grey-6: #2E363E;
$color-grey-7: #f1f1f1;
$color-grey-8: #f2f2f2;

$color-fuchsia: #F652F7;
$color-fuchsia-dark: #C90578;
$color-fuchsia-1: #E100A7;
$color-fuchsia-light: #C70374;
$color-blueberry: #3D2E3E;
$color-yellow: #EFF01A;
$color-green: #7EBA00;
$color-green-1: #6E8149;
$color-green-light: #9CE10D;
$color-green-light-1: #F4FFE6;

$color-primary: $color-fuchsia;
$color-secondary: $color-blueberry;

$body-color-text: $color-grey-1;
$body-background: $color-white;

$link-color: $color-fuchsia;
$link-color-hover: $color-blueberry;

// Containers
$container-padding: 20px;
$container-padding-mobile: 12px;
$container-width: 1290px;

// $container-max-width: calc(#{$container-width} + #{$container-padding} * 2);
// $container-max-width-mobile: calc(#{$container-width} + #{$container-padding-mobile} * 2);

$container-max-width: 1330px;
$container-min-width-mobile: 280px;


$font-primary: 'Uni Neue', Arial, Helvetica, sans-serif;
$font-secondary: 'Uni Neue', Arial, Helvetica, sans-serif;
$font-heading: 'Uni Neue', Arial, Helvetica, sans-serif;

$font-size-base: 62.5%;

$font-size-sm: 1rem;
$font-size-sm-1: 1.2rem;
$font-size-sm-2: 1.3rem;
$font-size-md: 1.4rem;
$font-size-md-1: 1.6rem;
$font-size-md-2: 1.8rem;
$font-size-md-3: 2rem;
$font-size-lg: 2.2rem;
$font-size-lg-1: 2.8rem;
//$font-size-lg-2: 2.8rem;
$font-size-lg-2: 3.2rem;
$font-size-xl: 3.6rem;
$font-size-xl-1: 4.2rem;
$font-size-xl-2: 5.2rem;
$font-size-xxl: 6.4rem;

// Body
$body-font-family: $font-primary;
$body-font-size: $font-size-md-2;
$body-font-weight: normal;
$body-line-height: 1.5;

/* Headings */
$heading-font-family: $font-heading;
$heading-line-height: 1;

$heading-font-size-h6: $font-size-md-2;
$heading-font-size-h5: $font-size-md-3;
$heading-font-size-h4: $font-size-lg;
$heading-font-size-h3: $font-size-lg-1;
$heading-font-size-h2: $font-size-xl-1;
$heading-font-size-h1: $font-size-xxl;

$heading-font-weight-h6: normal;
$heading-font-weight-h5: normal;
$heading-font-weight-h4: 500;
$heading-font-weight-h3: 500;
$heading-font-weight-h2: 800;
$heading-font-weight-h1: 800;

// paragraph
$paragraph-line-height: 1.4;

// Transition timeouts
$base-transition: 210ms;