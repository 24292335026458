@import '../../styles/utilities';

.quiz {
  .box-primary {
    padding: 5rem 10vw;

    @include media(mobile) {
      padding: 2rem;
    }
  }

  .quiz-status {
    margin: 4.6rem 0 3rem;
  }

  .quiz__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media(tablet) {
      flex-direction: column;
    }

    > div {
      flex: 1;
      display: flex;
      align-items: center;

      &:nth-child(1) {
        @include media(desktop) {
          align-items: flex-start;
          flex-direction: column;
        }

        @include media(tablet) {
          align-items: center;
          order: 1;

          .quiz__table-size {
            order: 1;
          }

          .quiz__price {
            order: 0;
          }

        }
      }

      &:nth-child(2) {
        min-width: 430px;
        justify-content: flex-end;

        @include media(desktop) {
          min-width: 380px;
        }

        @include media(tablet) {
          order: 0;
          min-width: auto;
        }
      }
    }
  }

  .quiz__table-size,
  .quiz__price {
    margin: .5rem 0;
  }

  .quiz__price {
    margin-left: 1rem;
    line-height: 1;

    @include media(desktop) {
      margin-left: 0;
    }

    @include media(tablet) {
      text-align: center;
    }

    .quiz__price-text {
      font-size: $font-size-md;
      color: $color-grey-1;
      font-weight: 800;
    }

    .quiz__price-amount {
      margin-left: .6rem;
      color: $color-primary;
      font-weight: 800;
      white-space: nowrap;
    }

    .quiz__price-note {
      display: block;
      margin-top: .2rem;
      font-size: $font-size-sm-2;
    }
  }

}