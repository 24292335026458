@import '../../../styles/utilities';

.quiz-external-modal {
  width: 80vw;
  height: 60vh;
  max-height: 580px;
  max-width: 1100px;
  padding: 0;
  overflow: hidden;


  @include media(tablet) {
    width: calc(100% - 40px);
    height: auto;
    max-height: none;
    max-width: none;
  }

  @include media(mobile-sm) {
    width: calc(100% - 20px);
  }

  .quiz-external-modal__inner {
    position: relative;
    height: 100%;
  }

  .quiz-external-modal__title {
    position: relative;
    padding: 2.4rem 4rem;
    text-align: center;
    color: $color-green-1;
    background: rgba($color-green-light, .5);
    z-index: 2;

    @include media(mobile-sm) {
      font-size: $font-size-md !important;
      padding: 1.8rem 1.4rem;
    }
  }

  .quiz-external-modal__main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 3rem;
    z-index: 2;

    @include media(tablet) {
      width: 100%;
      margin-left: 0;
      padding: 1rem;
    }
  }

  .quiz-external-modal__status {
    position: relative;
    display: flex;
    width: 70%;
    margin: 5rem 0;

    @include media(desktop) {
      width: 90%;
    }

    @include media(tablet) {
      width: 100%;
      margin: 2rem 0;
    }

    .quiz-status {
      width: 100%;
    }

    .quiz-progress {
      width: 100%;
      background: $color-fuchsia;
      opacity: .6;
    }

    .quiz-status__text {
      padding: 0 80px 0 10px;
      color: $color-white;
    }
  }

  .quiz-external-modal__content {
    display: flex;
    position: relative;
    max-width: 620px;
    padding-bottom: 2rem;
    z-index: 10;

    @include media(tablet) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }

  .quiz-external-modal__product {
    flex: 1;

    .quiz-product {
      .quiz-product__main {
        overflow: hidden;
        background: $color-white;
      }

      .quiz-product__title {
        margin-top: .4rem;
        padding: 1rem;
        background: $color-white;
        border-radius: 6px;
      }

      @include media(mobile) {
        .quiz-product__image {
          height: 100px;

          img {
            height: 100px;
          }
        }
      }
    }
  }

  .quiz-promo {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .quiz-promo__title {
      text-align: center;
    }

    .quiz-promo__pricing {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      background: $color-viber;
      border: 2px solid #D19CF5;
      border-radius: 8px;

      > * {
        margin: 1rem;
        font-weight: 500;
      }

      .quiz-promo__product-price {
        color: $color-white;
      }

      s {
        color: #D19CF5;
      }

      .quiz-promo__percent {
        color: $color-white;
      }
    }

    @include media(mobile-sm) {
      strong {
        font-size: $font-size-md;
      }
    }

    .quiz-promo__note {
      margin-top: 1rem;
      font-size: $font-size-sm-2;
    }

    .quiz-promo__amount {
      font-weight: bold;
      color: $color-fuchsia-dark;
      margin-top: .2rem;
    }
  }

  .quiz-external-modal__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    img {
      width: auto;
      height: 100%;
      object-fit: cover;
      object-position: right;

      @include media(tablet) {
        opacity: .4;
      }
    }

    .quiz-external-modal__image-title {
      position: absolute;
      width: 200px;
      bottom: 30px;
      left: 250px;
      color: $color-grey-1;

      @include media(desktop) {
        display: none;
      }
    }
  }
}