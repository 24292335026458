::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  border: 0;
  border-radius: 0;
  background: $color-primary;

  &:hover,
  :active {
    background: $color-secondary;
  }
}

::-webkit-scrollbar-track {
  border: 0;
  border-radius: 0;
  background: $color-secondary;

  &:hover,
  :active {
    background: transparent;
  }
}

::-webkit-scrollbar-corner {
  background: transparent;
}